import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2f0e6a54"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "sidebar-sub-menu ps-0 mb-0 list-unstyled",
  id: "menu-patients"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lists, (list) => {
      return (_openBlock(), _createElementBlock("li", {
        class: "sidebar-sub-menu-item",
        key: list.uuid,
        onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.handleClick($event)), ["prevent"]))
      }, [
        _createVNode(_component_router_link, {
          to: '/dashboard?report='+`${list.id}`,
          onClick: [
            ($event: any) => (_ctx.isActive(list.id)),
            _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.handleClick($event)), ["prevent"]))
          ],
          class: _normalizeClass(['sidebar-sub-menu-link', { active: _ctx.activeIndex === list.uuid }])
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(list.name), 1)
          ]),
          _: 2
        }, 1032, ["to", "onClick", "class"])
      ]))
    }), 128))
  ]))
}